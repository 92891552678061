import React from 'react';
import './App.css';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import aws_exports from './aws-exports';
import cav4_exports from './cav4-exports';

class App extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            login: "",

            loggingIn: false,

            loggedUser: null,

            globalSessionId: null,

            errorMessage: null,
            successMessage: null
        };
    }

    componentDidMount() {

        const queryParams = new URLSearchParams(window.location.search);
        const globalSessionId = queryParams.get("globalSessionId");
        const login = queryParams.get("login");

        if (globalSessionId && (/^[\w\d]+$/).test(globalSessionId)) {
            if (login && (/^[\w\d_]{4,30}/).test(login)) {
                this.setState({globalSessionId, login});
                this.handleGlobalSessionId();
            } else {
                this.showError("Erro na autenticação: Login não informado. Tente novamente.");
            }
        };    
    }

    submitForm = event => {
        event.preventDefault();

        if (this.state.login) {
            this.handleAccess(event);
        }
    }

    handleAccess = async event => {
        event.preventDefault();

        const cav4Url = cav4_exports.url.replace("{{successfulUrl}}", `${window.location.protocol}//${window.location.host}${window.location.pathname}?login=${this.state.login}`)
        
        console.log("Redirecionando para o CAv4:");
        this.showSuccess("Redirecionando para a tela de login...");

        console.log(cav4Url);

        window.location.href = cav4Url;
    }

    handleGlobalSessionId = () => {
        this.signOut();
    }

    signOut = () => {
        console.log("Sign out...");
        Auth.signOut()
        .then(() => {
            console.log("Signed out...");
            this.signIn();
        })
        .catch(error => {
            this.setState({authError: true});            
            console.log('Erro no sign out:')
            console.log(error);
        });
    }

    firstSignIn = () => {
        this.setState({loggingIn: true});

        console.log("Sign in...");

        this.showSuccess("Realizando login no AppStream...");

        Auth.signIn(this.state.login, this.state.globalSessionId)
        .then(user => {

            console.log("Signed in.");
            console.log("user:");
            console.log(user);
            this.setState({
                loggedUser: user
            });

            this.showSuccess("Login realizado com sucesso.");
            this.generateAppstreamUrl();
        })
        .catch(error => {
            console.log('Erro no primeiro signIn:')
            console.log(error);

            this.showError("Erro ao realizar Login. Tente novamente.");

            this.setState({loggingIn: false});
        });
    }

    signIn = () => {

        this.setState({loggingIn: true});

        console.log("Sign in...");

        this.showSuccess("Realizando login no AppStream...");

        Auth.signIn(this.state.login)
        .then(user => {

            console.log("Signed in.");
            console.log("user:");
            console.log(user);
            this.setState({
                loggedUser: user
            });

            console.log("Sending CustomChallengeAnswer...");
            Auth.sendCustomChallengeAnswer(this.state.loggedUser, this.state.globalSessionId, {})
            .then(() => {
                console.log("Sent CustomChallengeAnswer.");
                this.showSuccess("Login realizado com sucesso.");
                this.generateAppstreamUrl()
            })
            .catch(error => {
                this.showError("Erro ao realizar o Login. Tente novamente.");
                console.log('Erro no sendCustomChallengeAnswer:')
                console.log(error);

                this.setState({loggingIn: false});
            });
        })
        .catch(error => {
            
            console.log('Erro no signIn:')
            console.log(error);

            this.setState({loggingIn: false});

            // Tenta realizar o login com MigrateUser
            this.firstSignIn();
        });
    }

    showError = (errorMessage) => {
        this.setState({errorMessage, successMessage: null});
    }

    showSuccess = (successMessage) => {
        this.setState({successMessage, errorMessage: null});
    }
    
    generateAppstreamUrl = async () => {
        console.log("generating AppstreamUrl...");

        this.showSuccess("Gerando URL para acesso ao AppStream...");

        try {
            const user = await Auth.currentAuthenticatedUser();
            const token = user.signInUserSession.idToken.jwtToken;
            const account = { action: 'appstreamSession' };
            const config = {
                contentType: 'application/json',
                headers :{
                    Authorization: token
                }
            };

            axios.post(aws_exports.apiAppstreamEndpoint+'/auth', account,config)
            .then(response => {
                this.showSuccess("URL gerada. Redirecionando para o AppStream...");
                this.redirectAppstream(response.data);
            })
            .catch(error => this.showError("Erro ao gerar a URL para o AppStream. Tente novamente."));
        } catch(e) {
            console.log("Erro ao gerar AppstreamUrl...");
            this.showError("Erro ao gerar a URL para o AppStream.")
            console.log(e);
        }
    }
    
    redirectAppstream = (data) => {
        console.log("redirectAppstream...");
        if (data) {
            console.log(data.Message);
            window.location.href = data.Message;
        }
    }

    handleChange = (event) => {
        this.setState({login: event.target.value});
      }

    handleAuthError = () => {
        this.setState({
            login: "",
            loggedUser: null
        });
    }

    render() {
        const { login, successMessage, errorMessage, loggingIn } = this.state;
        return (
            <div className="App">
                <div className="container" id="container">
                    <img className="App-logo" src="./petrobras.png" alt="Petrobras" />
                    <div className="form-container">
                        <form onSubmit={this.submitForm}> 
                            <div>
                                Entrar
                            </div>
                            <div>
                                <input id="txtLogin" type="text" placeholder="Login" value={this.state.login} onChange={this.handleChange} disabled={loggingIn} />                                

                                <svg height="26" width="26" viewBox='0 0 26 26'>
                                    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z">
                                    <title>
                                        Faça o logon com sua chave de usuário externo do SIGEM. Exemplo: XYZ_AB12_SIGEM. 
                                        Em situações excepcionais, usuários com chave corporativa podem acessar utilizando sua chave de quatro caracteres.
                                    </title>
                                    </path>
                                </svg>
                            </div>
                            <div>
                                <button disabled={!login || loggingIn} onClick={this.handleAccess}>Acessar</button>
                            </div>

                        </form>
                    </div>
                    <div className='alert-container alert-error' style={{ display: (errorMessage ? 'block' : 'none') }}>
                        {errorMessage}
                    </div>
                    <div className='alert-container alert-sucesss' style={{ display: (successMessage ? 'block' : 'none') }}>
                        {successMessage}
                    </div>
                    <div style={{marginTop: "20px"}}>
                        Dúvidas sobre o acesso? Veja as orientações <a href='orientacoes.docx'>aqui</a>.
                    </div>
                </div>
            </div>
        );
    };
}

export default App;
