const awsmobile = {
    "aws_project_region": "sa-east-1",
    "aws_cognito_region": "sa-east-1",
    //"aws_user_pools_id": "us-east-1_d8daiTvGw", // UserPoolId
    "aws_user_pools_id": "sa-east-1_Wi5CqjNdm", // UserPoolId
    // "aws_user_pools_web_client_id": "30eogml69aomuuef10sgg3s2fn", // UserPoolClientId
    "aws_user_pools_web_client_id": "4u026ihkp8c96aeu4lg6p1u0mj", // UserPoolClientId
    // "apiAppstreamEndpoint": "https://76dvsl4wrl.execute-api.us-east-1.amazonaws.com/prod", // ApiGWInvokeUrl
    "apiAppstreamEndpoint": "https://6sk13ntmve.execute-api.sa-east-1.amazonaws.com/prod" // ApiGWInvokeUrl
};

export default awsmobile;
